import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from '@core/core.module';
import { TranslateService } from '@ngx-translate/core';
import { de, en } from './modules/_i18n';
import { TranslationLoaderService } from '@bws-dev/ngx-bws-shared';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    AppRoutingModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(translationLoader: TranslationLoaderService,
              translate: TranslateService) {

    // Load global translations
    translationLoader.loadTranslations(en, de);

    // Add languages
    translate.addLangs(['en', 'de']);
  }

}

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { AuthService } from '@http/auth.service';
import { environment } from '@environment';
import { ErrorModel } from '@models/error.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AuthUserModel } from '@models/auth-user.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import * as Sentry from "@sentry/angular-ivy";

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

  refreshTokenInProgress = false;
  tokenRefreshedSource = new Subject<void>();
  tokenRefreshed$ = this.tokenRefreshedSource.asObservable();

  constructor(private authService: AuthService,
              private http: HttpClient,
              private router: Router,
              private toastr: ToastrService,
              private translate: TranslateService,
              private localize: LocalizeRouterService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {

    // Pass login request without modification
    if (req.url.indexOf('/login') !== -1) {
      return next.handle(req);
    }

    return next.handle(this.appendToken(req))
      .pipe(
        catchError(error => {
          if (error.status === 401) {
            // Retry community request without token and log out
            if (error.url.indexOf('/community') !== -1) {
              this.authService.logout();
              return next.handle(req);
            }
            if (error.url.indexOf('/auth/refresh') !== -1) {
              this.refreshTokenInProgress = false;
              this.toastr.warning(this.translate.instant('GLOBAL.SESSION-EXPIRED'));
              this.router.navigate([this.localize.translateRoute('/member/login')]);
              return Observable.create('');
            }
            return this.refreshToken().pipe(switchMap(() => {
              return next.handle(this.appendToken(req)).pipe(catchError(this.handleError));
            }));
          }
          return this.handleError(error);
        })
      );

  }

  private appendToken(req: HttpRequest<any>): HttpRequest<any> {
    if (this.authService.isLoggedIn() && this.authService.authenticatedUser?.access) {
      return req.clone({ setHeaders: { Authorization: 'Bearer ' + this.authService.authenticatedUser.access.token }});
    } else {
      return req;
    }
  }

  refreshToken() {
    if (this.refreshTokenInProgress) {
      return new Observable(observer => {
        this.tokenRefreshed$.subscribe(() => {
          observer.next();
          observer.complete();
        });
      });
    } else {
      this.refreshTokenInProgress = true;
      const url = environment.memberApiUrl + 'auth/refresh';
      const body = { token: this.authService.authenticatedUser!.refresh.token };
      return this.http.post<AuthUserModel>(url, body)
        .pipe(
          map(refreshResponse => {
            this.authService.setToken(refreshResponse);
            return refreshResponse;
          }),
          tap(() => {
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next();
          })
        );
    }
  }

  private handleError(error: HttpErrorResponse): Observable<string> {

    // Capture sentry event
    Sentry.captureException(`Server returned code ${error.status} with body "${JSON.stringify(error.error)}"`);

    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.message);
    } else {
      // The backend returned an unsuccessful response code.
      if (error.error?.message) {
        const errModel = error.error as ErrorModel;
        return throwError(errModel.message);
      } else {
        return throwError(error.message);
      }
    }
  }

}

<div class="d-flex flex-column flex-root app-root w-100">
  <!--begin::Page-->
  <div class="app-page flex-column flex-column-fluid ">

    <!--
    <bws-header class="d-block d-lg-none"></bws-header>
    -->

    <!--begin::Wrapper-->
    <div class="app-wrapper flex-column flex-row-fluid">

      <bws-sidebar [navLinks]="navLinks">
        <ng-container logo>
<!--
          <img alt="Logo" src="/assets/images/logos/logo-square-dark.svg" class="h-200px my-n10">
-->
          <img alt="Logo" src="/assets/images/logos/logo-white.svg" class="w-275px">
        </ng-container>
      </bws-sidebar>

      <!--begin::Main-->
      <div class="app-main flex-column flex-row-fluid">

        <div class="d-flex flex-column flex-column-fluid">
          <div class="flex-column-fluid">

            <!-- Notification -->
            <ng-container *ngIf="clientNotificationService.notification | async as not">
              <ng-container *ngIf="not.content">
                <bws-alert hideicon light [type]="getNotificationType(not.type)" class="top-alert">
                  <div class="notification-content">
                    <div><img *ngIf="not.imageUrl" [src]="not.imageUrl" class="image" [alt]="not.name"></div>
                    <div [innerHTML]="not.content[0].body|safePipe:'html'" class="pe-5"></div>
                    <div class="flex-shrink-0">
                      <a *ngIf="not.content[0].actionUrl" class="btn btn-sm btn-link"
                         [href]="not.content[0].actionUrl" target="_blank">
                        <bws-mdi name="open-in-new"/> {{not.content[0].actionContent}}
                      </a>
                    </div>
                  </div>
                </bws-alert>
              </ng-container>
            </ng-container>


            <router-outlet></router-outlet>
          </div>
        </div>

        <app-footer class="bg-white" />

        <!--<bws-footer></bws-footer>-->
      </div>

    </div>
    <!--end::Wrapper-->


  </div>
  <!--end::Page-->
</div>

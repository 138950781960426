import { ErrorHandler, isDevMode } from '@angular/core';
import * as Sentry from "@sentry/angular-ivy";
import { environment } from '@environment';

export class SentryErrorHandler implements ErrorHandler {

  constructor() {
    if (!isDevMode()) {
      Sentry.init({
        dsn: "https://f3a4f3bbce156699aab2e782cd1e7cd0@sentry.bws-dev.com/2",
        release: environment.version,
        // environment: environment.production ? 'prod' : 'dev',
        environment: 'prod',

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        // replaysSessionSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        integrations: [
          new Sentry.BrowserProfilingIntegration(),
  //        new Sentry.Replay()
        ],
      });
    }
  }

  handleError(error: any) {
    if (isDevMode()) {
      console.error(error);
    } else {
      const eventId = Sentry.captureException(error);
    }
  }
}

export function provideErrorHandler() {
  return new SentryErrorHandler();
}

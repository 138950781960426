import { NgModule } from '@angular/core';
import { CommunityToastComponent } from '@shared/community-toast/community-toast.component';
import { BwsLayoutModule, BwsSharedModule } from '@bws-dev/ngx-bws-shared';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import player from 'lottie-web/build/player/lottie_svg';

import { TranslateCutPipe } from '@shared/pipes/translate-cut.pipe';
import { TruncatePipe } from '@shared/pipes/truncate.pipe';
import { CeilPipe } from '@shared/pipes/ceil.pipe';
import { SlugPipe } from '@shared/pipes/slug.pipe';
import { GalleryModule } from 'ng-gallery';
import { LightboxModule } from 'ng-gallery/lightbox';
import { CommonModule } from '@angular/common';
import { HeaderContentComponent } from '@shared/header-content/header-content.component';
import { GtagModule } from '@shared/gtag/gtag.module';
import { StickyDirective } from '@shared/sticky.directive';
import {HighlightSearchPipe} from "@shared/pipes/highlight-search.pipe";

@NgModule({
  imports: [
    BwsLayoutModule,
    BwsSharedModule,
    GtagModule.forRoot({
      trackingId: '',
      trackPageviews: true,
    }),

    LottieModule.forRoot({ player: () => player }),
    LottieCacheModule.forRoot(),
  ],
  declarations: [
    HeaderContentComponent,
    CommunityToastComponent,
    StickyDirective,

    TranslateCutPipe,
    TruncatePipe,
    CeilPipe,
    SlugPipe,
    HighlightSearchPipe,
  ],
  exports: [
    CommonModule,

    BwsLayoutModule,
    BwsSharedModule,
    GtagModule,

    LottieModule,
    GalleryModule,
    LightboxModule,

    // Shared Components
    HeaderContentComponent,
    CommunityToastComponent,
    StickyDirective,

    // Pipes
    TranslateCutPipe,
    TruncatePipe,
    CeilPipe,
    SlugPipe,
    HighlightSearchPipe,
  ]
})
export class SharedModule { }

import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { environment } from '@environment';
import { catchError, tap } from 'rxjs/operators';
import { Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ErrorModel } from '@models/error.model';
import { AuthUserModel, UserRoleType } from '@models/auth-user.model';
import { CustomReuseStrategy } from '@core/route-reuse-strategy';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import Swal from 'sweetalert2';
import { PlatformService } from '@bws-dev/ngx-bws-shared';
import { Gtag } from '@shared/gtag/gtag.service';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public loginStatusChange: Subject<boolean> = new Subject<boolean>();
  public authenticatedUser?: AuthUserModel;
  public redirectUrl: string = '';
  public redirectParams?: Params;

  constructor(private http: HttpClient,
              private router: Router,
              private translate: TranslateService,
              private toastr: ToastrService,
              private localize: LocalizeRouterService,
              private ps: PlatformService,
              private gtag: Gtag) {
    if (ps.isBrowser) {
      const data = localStorage.getItem(environment.localStorageModel);
      if (data) {
        this.setAuthData(JSON.parse(data));
      }
    }
  }

  private setAuthData(authData?: AuthUserModel) {
    this.authenticatedUser = authData;
    if (this.ps.isBrowser) {
      if (authData) {
        localStorage.setItem(environment.localStorageModel, JSON.stringify(authData));
        this.loginStatusChange.next(true);
        Sentry.setUser({ id: this.authenticatedUser?.userId, email: this.authenticatedUser?.email });
      } else {
        localStorage.clear();
        this.loginStatusChange.next(false);
        Sentry.setUser(null);
      }
    }
  }


  login(email: string, password: string): Observable<AuthUserModel> {
    return this.http.post<AuthUserModel>(environment.memberApiUrl + 'auth/login', {email: email, password: password})
      .pipe(tap(response => { this.setAuthData(response); }))
      .pipe(tap(() => { this.gtag.event('login', {});}));
  }
  resetPassword(email: string): Observable<any> {
    return this.http.post<any>(environment.memberApiUrl + 'auth/password/reset', {email: email});
  }
  resetPasswordCheck(session: string): Observable<any> {
    return this.http.get<any>(environment.memberApiUrl + 'auth/password?session=' + session);
  }
  executePasswordCheck(session: string, newPassword: string): Observable<any> {
    return this.http.post<any>(environment.memberApiUrl + 'auth/password?session=' + session, {password: newPassword});
  }
  resendValidationEmail(email: string): Observable<any> {
    return this.http.post<any>(environment.memberApiUrl + 'register/validate', {email: email});
  }
  executeEmailChange(sessionId: string): Observable<any> {
    return this.http.get<any>(environment.memberApiUrl + 'auth/mail?session=' + sessionId);
  }
  validateAccount(sessionId: string): Observable<any> {
    return this.http.get<any>(environment.memberApiUrl + 'register/validate?session=' + sessionId);
  }

  logout(): void {
    this.setAuthData(undefined);
    // deactivate all router handles
    if ((this.router.routeReuseStrategy as CustomReuseStrategy).deactivateAllHandles) {
      (this.router.routeReuseStrategy as CustomReuseStrategy).deactivateAllHandles();
    }
  }


  setToken(authData: AuthUserModel) {
    if (this.authenticatedUser) {
      this.authenticatedUser.access = authData.access;
      this.authenticatedUser.refresh = authData.refresh;
      localStorage.setItem(environment.localStorageModel, JSON.stringify(this.authenticatedUser));
    }
  }

  redirect() {
    // if redirect url is not set to login, route
    if (this.redirectUrl && this.redirectUrl.indexOf('login') === -1) {
      this.router.navigate([this.redirectUrl], { queryParams: this.redirectParams || undefined });
    } else {
      this.router.navigate([this.localize.translateRoute('/member/dashboard')]);
    }
  }

  isLoggedIn() {
    return !!this.authenticatedUser;
  }

  isAdmin() {
    return this.authenticatedUser && this.authenticatedUser.role === UserRoleType.ADMIN;
  }

  openLoginSwal(emailValue = '', passwordValue = '') {

    const emailPlaceholder = this.translate.instant('GLOBAL.LOGIN-MODAL.E-MAIL-PLACEHOLDER');
    const passwordPlaceholder = this.translate.instant('GLOBAL.LOGIN-MODAL.PASSWORD-PLACEHOLDER');
    const modalHead = this.translate.instant('GLOBAL.LOGIN-MODAL.HEAD');

    Swal.fire({
      title: this.translate.instant('GLOBAL.LOGIN-MODAL.LOGIN'),
      html: `
      <div class="has-text-left mb-3">
        ${modalHead}
      </div>
      <div class="control mb-3">
        <input class="form-control form-control-lg form-control-solid" type="email" placeholder="${emailPlaceholder}" value="${emailValue}" id="swal-email">
      </div>
      <div class="control">
        <input class="form-control form-control-lg form-control-solid" type="password" placeholder="${passwordPlaceholder}" value="${passwordValue}" id="swal-password">
      </div>
      `,
      focusConfirm: false,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      heightAuto: false,
      allowOutsideClick: () => !Swal.isLoading(),
      didOpen: (swal) => {
        const confirmBtn = (<HTMLButtonElement>swal.getElementsByClassName('swal2-confirm')[0]);

        const inputElements = [
          (<HTMLInputElement>document.getElementById('swal-email')),
          (<HTMLInputElement>document.getElementById('swal-password')),
        ];

        confirmBtn.disabled = !inputElements[0].value || !inputElements[1].value;

        inputElements.forEach(iel => {
          iel.oninput = (val) => {
            confirmBtn.disabled = !inputElements[0].value || !inputElements[1].value;
          };
          iel.onkeyup = (event) => {
            if (event.keyCode === 13 && !confirmBtn.disabled) {
              confirmBtn.click();
            }
          }
          ;
        });
      },
      preConfirm: () => {
        const inputElements = [
          (<HTMLInputElement>document.getElementById('swal-email')),
          (<HTMLInputElement>document.getElementById('swal-password')),
        ];
        emailValue = inputElements[0].value;
        passwordValue = inputElements[1].value;
        return this.login(emailValue, passwordValue)
          .pipe(catchError(this.handleError))
          .toPromise()
          .then(() => {
            this.toastr.success(this.translate.instant('GLOBAL.LOGIN-MODAL.LOGIN-SUCCESS'));
            Swal.close();
          })
          .catch((e) => {
            this.toastr.error(e);
            Swal.close();
            setTimeout(() => {
              this.openLoginSwal(emailValue, passwordValue);
            });
          });
      }});
  }


  logoutCommunity() {
    // clear token remove user from local storage to log user out
    this.authenticatedUser = undefined;
    localStorage.clear();
    this.loginStatusChange.next(false);
    this.toastr.success(this.translate.instant('GLOBAL.LOGOUT-SUCCESS'));
  }

  private handleError(error: HttpErrorResponse): Observable<string> {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(error.message);
    } else {
      // The backend returned an unsuccessful response code.
      if (error.error.error_type) {
        const errModel = error.error.error_type as ErrorModel;
        return throwError(errModel.message);
      } else if (error.error.message) {
        const errModel = error.error as ErrorModel;
        return throwError(errModel.message);
      } else {
        return throwError(error.message);
      }
    }
  }

}

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environmentLoader } from './environments/environmentLoader';
import { environment } from '@environment';


environmentLoader.then(env => {

  if (env.baseUrl && !env.baseUrl.startsWith('$')) {
    environment.baseUrl = env.baseUrl;
  }
  if (env.memberApiUrl && !env.memberApiUrl.startsWith('$')) {
    environment.memberApiUrl = env.memberApiUrl;
  }
  if (env.cmsApiUrl && !env.cmsApiUrl.startsWith('$')) {
    environment.cmsApiUrl = env.cmsApiUrl;
  }
  if (env.downloadDataUrl && !env.downloadDataUrl.startsWith('$')) {
    environment.downloadDataUrl = env.downloadDataUrl
  }
  if (env.currencyApiUrl && !env.currencyApiUrl.startsWith('$')) {
    environment.currencyApiUrl = env.currencyApiUrl
  }
  if (env.dataApiUrl && !env.dataApiUrl.startsWith('$')) {
    environment.dataApiUrl = env.dataApiUrl
  }
  if (env.imageUploadUrl && !env.imageUploadUrl.startsWith('$')) {
    environment.imageUploadUrl = env.imageUploadUrl
  }
  if (env.wssUrl && !env.wssUrl.startsWith('$')) {
    environment.wssUrl = env.wssUrl;
  }
  if (env.blogApiUrl && !env.blogApiUrl.startsWith('$')) {
    environment.blogApiUrl = env.blogApiUrl;
  }
  if (env.blogKey && !env.blogKey.startsWith('$')) {
    environment.blogKey = env.blogKey;
  }
  if (env.vatPercent && !env.vatPercent.startsWith('$')) {
    environment.vatPercent = Number(env.vatPercent);
  }
  if (env.localStorageModel && !env.localStorageModel.startsWith('$')) {
    environment.localStorageModel = env.localStorageModel;
  }

  platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

});


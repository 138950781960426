// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const packageJson = require('../../package.json');

const prod = false;

export const environment = {
  production: false,
  version: packageJson.version,

  baseUrl: prod ? 'https://dewabit.com' : 'https://dev.dewabit.com',
  memberApiUrl: prod ? 'https://member-api.dewabit.com/v2/' : 'https://member-api.dev.dewabit.com/v2/',
  cmsApiUrl: prod ? 'https://cms-api.dewabit.com/' : 'https://cms-api.dev.dewabit.com/',
  downloadDataUrl: 'https://updates.dewabit.com/update-details.php',
  currencyApiUrl: 'https://dev.dewabit.com/api/currencies',
  dataApiUrl: prod ? 'https://data.dewabit.com/' : 'https://data.dev.dewabit.com/',
  imageUploadUrl: prod ? 'https://data.dewabit.com/c/images' : 'https://data.dev.dewabit.com/c/images',
  wssUrl: prod ? 'wss://ws.dewabit.com/ws' : 'wss://ws.dev.dewabit.com/ws',
  blogApiUrl: 'https://blog.dewabit.com/ghost/api/v2/',
  blogKey: 'd466d24b45bafbb4b0a372a02f',
  vatPercent: 19,
  localStorageModel: 'DwbAuthUserModel',
};

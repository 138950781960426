import { Component, OnInit } from '@angular/core';
import { BwsNavLink, PlatformService } from '@bws-dev/ngx-bws-shared';
import { ClientNotificationService } from '@http/client-notification.service';
import { ClientNotificationType } from '@models/client-notification.model';
import { CookieConsentService } from '@core/services/cookie-consent.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  constructor(public clientNotificationService: ClientNotificationService,
              private platformService: PlatformService,
              private cookieConsent: CookieConsentService) {}

  public navLinks: BwsNavLink[] = [
    { title: 'NAV-LINKS.HOME', icon: 'home', route: '/home' },
    { title: 'NAV-LINKS.FEATURES', icon: 'archive-star', route: '/features' },
    { title: 'NAV-LINKS.DOWNLOAD', icon: 'microsoft-windows', route: '/download' },
    { title: 'NAV-LINKS.PRICING', icon: 'cash-multiple', route: '/pricing' },
    { title: 'NAV-LINKS.FAQ', icon: 'frequently-asked-questions', route: '/faq' },
    { title: 'NAV-LINKS.HELP-PAGES', icon: 'book-open-variant', route: '/help' },
    { title: 'NAV-LINKS.FORUMS', icon: 'forum', route: '/forum' },
    { title: 'NAV-LINKS.BLOG', icon: 'typewriter', route: '/blog' },
    { title: 'NAV-LINKS.CONTACT', icon: 'email', route: '/contact' },
    { separator: true },
    { title: 'NAV-LINKS.MEMBER', icon: 'account', children: [
        { title: 'NAV-LINKS.MEMBER-DASHBOARD', icon: 'view-dashboard-variant', route: '/member/dashboard' },
        { title: 'NAV-LINKS.MEMBER-PROFILE', icon: 'account-details', route: '/member/profile' },
        { title: 'NAV-LINKS.MEMBER-SUBSCRIPTION', icon: 'cash-multiple', route: '/member/subscription' },
        { title: 'NAV-LINKS.MEMBER-EBAY-ACCOUNTS', icon: 'account-group', route: '/member/accounts' },
        { title: 'NAV-LINKS.MEMBER-TEMPLATES', icon: 'format-paint', route: '/member/templates' },
        { title: 'NAV-LINKS.MEMBER-APPS', icon: 'application-brackets', route: '/member/apps' },
        { title: 'NAV-LINKS.MEMBER-SUPPORT', icon: 'lifebuoy', route: '/member/support' },
      ]
    },
  ];

  ngOnInit() {
    if (this.platformService.isBrowser) {
      this.clientNotificationService.checkNotification();
      this.cookieConsent.initCookieConsent();
    }
  }

  getNotificationType(type: ClientNotificationType): 'DANGER' | 'INFO' | 'SUCCESS' | 'WARNING' {
    return type.toUpperCase().replace('INFORMATION', 'INFO') as any;
  }


  getBtnType(type: ClientNotificationType): string {
    return 'btn-' + type.toLowerCase().replace('information', 'info');
  }


}

<div class="container d-flex flex-column flex-md-row flex-center flex-md-stack py-5 text-gray-700">
  <p class="mb-1 fw-semibold d-flex align-items-center">
    <a href="https://bws-dev.com" alt="BWS" target="_blank">
      <img src="/assets/images/bws-logo.png" alt="bws" class="h-20px me-3">
    </a>
    <!--<span> © Biela &amp; Waßenberg Software GbR </span>-->
  </p>
<!--
  <span class="fs-7 fw-bold">
    Made with <bws-mdi name="heart" class="text-danger"/> in Cologne
  </span>
-->
  <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
    <li class="menu-item"><a routerLink="{{'/legal/terms'|localize}}" class="menu-link px-2">{{'NAV-LINKS.TERMS'|translate}}</a></li>
    <li class="menu-item"><a routerLink="{{'/legal/privacy'|localize}}" class="menu-link px-2">{{'NAV-LINKS.PRIVACY'|translate}}</a></li>
    <li class="menu-item"><a routerLink="{{'/legal/imprint'|localize}}" class="menu-link px-2">{{'NAV-LINKS.IMPRINT'|translate}}</a></li>
  </ul>
</div>

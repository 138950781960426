import { inject, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { DefaultLayoutComponent } from '@core/layouts/default-layout/default-layout.component';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterService,
  LocalizeRouterSettings,
  ManualParserLoader
} from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { PlatformService } from '@bws-dev/ngx-bws-shared';

/** Redirects here */
export const redirectGuard = () => {
  const ps = inject(PlatformService);
  if (!ps.isBrowser) {
    return true;
  }
  const router = inject(Router);
  const localizedService = inject(LocalizeRouterService);
  const urlSearchParams: any = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const redirects: any = {
    '/error': '/error',
    '/login': '/member/login',
    '/login/reset': '/member/reset-password',
    '/dash/home': '/member/dashboard',
    '/dash/profile': '/member/profile',
    '/dash/subscription': '/member/subscription',
    '/dash/accounts': '/member/accounts',
    '/dash/templates': '/member/templates',
    '/dash/apps': '/member/apps',
    '/dash/support': '/member/support',
    '/dash/support/tickets': '/member/support',
  }
  const newRoute = (newRoute: string) => { return router.navigate([localizedService.translateRoute(newRoute)], { queryParams: params }); }
  return redirects[location.pathname] ? newRoute(redirects[location.pathname]) : true;
}


const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [],
    children: [
      { path: '', redirectTo: '/home', pathMatch: 'full' },
      { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
      { path: 'features', loadChildren: () => import('./modules/features/features.module').then(m => m.FeaturesModule) },
      { path: 'download', loadChildren: () => import('./modules/download/download.module').then(m => m.DownloadModule) },
      { path: 'pricing', loadChildren: () => import('./modules/pricing/pricing.module').then(m => m.PricingModule) },
      { path: 'help', loadChildren: () => import('./modules/help-pages/help-pages.module').then(m => m.HelpPagesModule) },
      { path: 'member', loadChildren: () => import('./modules/member/member.module').then(m => m.MemberModule) },
      { path: 'register', loadChildren: () => import('./modules/register/register.module').then(m => m.RegisterModule) },
      { path: 'error', loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule) },
      { path: 'legal', loadChildren: () => import('./modules/legal/legal.module').then(m => m.LegalModule) },
      { path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule) },
      { path: 'forum', loadChildren: () => import('./modules/community/community.module').then(m => m.CommunityModule), data: { title: '', skipRouteLocalization: true, discriminantPathKey: 'forum' } },
      { path: 'blog', loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule), data: { title: '', skipRouteLocalization: true, discriminantPathKey: 'blog' } },
      { path: 'faq', loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule), data: { title: '', skipRouteLocalization: true, discriminantPathKey: 'faq' } },
      { path: 'ssr-loading', loadChildren: () => import('./modules/ssr-loading/ssr-loading.module').then(m => m.SsrLoadingModule), data: { skipRouteLocalization: true, discriminantPathKey: 'ssr-loading' } },
    ]
  },
  { path: 'client-blog', loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule), data: {skipRouteLocalization: true, clientMode: true} },
  { path: 'client-help', loadChildren: () => import('./modules/help-pages/help-pages.module').then(m => m.HelpPagesModule), data: {skipRouteLocalization: true, clientMode: true} },
  { path: 'redirect', loadChildren: () => import('./modules/redirect/redirect.module').then(m => m.RedirectModule), data: {skipRouteLocalization: true} },
  {
    path: '**',
    component: DefaultLayoutComponent,
    canActivate: [redirectGuard],
    children: [{ path: '**', loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule), }]
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      initialNavigation: 'disabled', // 'enabledBlocking'
      anchorScrolling: 'enabled'
    }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: (translate: TranslateService, location: any, settings: any) =>
          new ManualParserLoader(translate, location, settings, translate.getLangs(), 'ROUTES.'),
        deps: [TranslateService, Location, LocalizeRouterSettings]
      },
      initialNavigation: true,
      useCachedLang: true
    })
  ],
  exports: [
    RouterModule,
  ]
})
export class AppRoutingModule { }

import { NgModule } from '@angular/core';
import { DefaultLayoutComponent } from './default-layout/default-layout.component';
import { FooterComponent } from './default-layout/footer/footer.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    DefaultLayoutComponent,
    FooterComponent
  ],
  exports: [
  ],
  imports: [
    SharedModule,
  ]
})
export class LayoutsModule { }

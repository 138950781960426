import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { take } from 'rxjs/operators';
import { ClientNotificationModel } from '@models/client-notification.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ClientNotificationService {

  notification: Subject<ClientNotificationModel> = new Subject<ClientNotificationModel>();

  constructor(private http: HttpClient,
              private translate: TranslateService) {
  }

  public checkNotification() {
    this.http.get<ClientNotificationModel>(environment.dataApiUrl + 'client/notifications?client=HOMEPAGE&locale=' + this.translate.currentLang)
      .pipe(take(1))
      .subscribe(m => {
        this.notification.next(m);
      }, e => {
        // ignored
      });
  }

}

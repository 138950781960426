import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlatformService } from '@bws-dev/ngx-bws-shared';

// case-insensitive check against config and value
const startsWithAny = (arr: string[] = []) => (value = '') => {
  return arr.some(test => value.toLowerCase().startsWith(test.toLowerCase()));
};

// http, https, protocol relative
const isAbsoluteURL = startsWithAny(['http', '//']);

@Injectable()
export class UniversalRelativeInterceptor implements HttpInterceptor {

  constructor(private platform: PlatformService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.platform.isBrowser && !isAbsoluteURL(req.url)) {
      // Redirect to local dev server
      const pathSeparator = !req.url.startsWith('/') ? '/' : '';
      const url = 'http://localhost:4200' + pathSeparator + req.url;
      const serverRequest = req.clone({ url });
      return next.handle(serverRequest);
    } else {
      return next.handle(req);
    }
  }
}

export const en = {
  lang: 'en',
  data: {
    'ROUTES': {
      'home': 'home',
      'features': 'features',
      'pricing': 'pricing',
      'faq': 'faq',
      'help': 'help',
      'forum': 'forum',
      'blog': 'blog',
      'member': 'member',
      'login': 'login',
      'dashboard': 'dashboard',
      'support': 'support',
      'tickets': 'tickets',
      'register': 'register',
      'complete': 'complete',
      'download': 'download',
      'contact': 'contact',
      'profile': 'profile',
      'general': 'general',
      'address': 'address',
      'company': 'company',
      'other': 'other',
      'subscription': 'subscription',
      'manage': 'verwalten',
      'accounts': 'accounts',
      'templates': 'templates',
      'apps': 'apps',
      'legal': 'legal',
      'terms': 'terms',
      'privacy': 'privacy',
      'imprint': 'imprint',
      'error': 'error',
      'item-management': 'item-management',
      'import-export': 'import-export',
      'synchronization': 'synchronisation',
      'orders': 'orders',
      'messages': 'messages',
      'marketing': 'marketing',
      'extra': 'extra',
    },
    'NAV-LINKS': {
      'HOME': 'Home',
      'FEATURES': 'Features',
      'DOWNLOAD': 'Download',
      'PRICING': 'Pricing',
      'FAQ': 'FAQ',
      'HELP-PAGES': 'Help pages',
      'FORUMS': 'Forums',
      'BLOG': 'Blog',
      'CONTACT': 'Contact us',
      'LOGIN': 'Login',
      'REGISTER': 'Register',
      'MEMBER': 'Member',
      'MEMBER-DASHBOARD': 'Dashboard',
      'MEMBER-PROFILE': 'Profile',
      'MEMBER-SUBSCRIPTION': 'Your plan',
      'MEMBER-EBAY-ACCOUNTS': 'ebay-Accounts',
      'MEMBER-TEMPLATES': 'Templates',
      'MEMBER-APPS': 'Apps',
      'MEMBER-SUPPORT': 'Support',
      'IMPRINT': 'Imprint',
      'PRIVACY': 'Privacy',
      'TERMS': 'Terms',
      'LOGOUT': 'Logout',
    },
    'GLOBAL': {
      'ERROR': 'Error',
      'ERROR-GENERAL': 'Something went wrong. Please try again later or contact our support.',
      'SESSION-EXPIRED': 'Your session has expired, please log in again',
      'CANCEL': 'Cancel',
      'TRY-AGAIN': 'Try again',
      'PLEASE-WAIT': 'Please wait',
      'ARE-YOU-SURE': 'Are you sure?',
      'SUCCESS': 'Success',
      'DELETE': 'Delete',
      'WARNING': 'Warning',
      'INFO': 'Info',
      'CHARS-LEFT': 'chars left',
      'CLOSE': 'Close',
      'CLOSE-WINDOW': 'Close window',
      'COPY-TO-CLIPBOARD': 'Copy to clipboard',
      'YES': 'Yes',
      'NO': 'No',
      'CONFIRM': 'Confirm',
      'SELECT': 'Select',
      'ACTIVE': 'Active',
      'INACTIVE': 'Inactive',
      'DEFAULT': 'Default',
      'SAVE-CHANGES': 'Save changes',
      'FOOTER': {
        'IMPRINT': 'Imprint',
        'PRIVACY': 'Privacy',
        'TERMS': 'Terms',
      },
      'UPDATE-AVAILABLE': {
        'HEAD': 'Update available!',
        'BODY': 'A new version is available. Would you like to reload the page now? Please note: ' +
          'Unsaved changes will be lost.',
        'LATER': 'Later',
        'RELOAD': 'Reload page',
      },
      'WEBSOCKET': {
        'TICKET-REPLY': 'You have received a reply on your support ticket.'
      },
      'PAYMENT': {
        'SUBTOTAL': 'Subtotal without VAT',
        'VAT': '{{VATPERCENT}}% VAT of',
        'TOTAL': 'Total',
        'AMOUNT-TO-PAY': 'Amount to pay',
        'CB-TERMS': 'I have read the terms and conditions and agree to them',
        'OPEN-NEW-WINDOW': 'Open in new window',
        'CB-CANCELLATION': 'I demand and agree that you begin with the service ordered before the expiry of the revocation period. ' +
          'I know that my right of revocation expires with complete fulfilment of the contract.',
        'CONTINUE-PAYPAL': 'Continue to PayPal',
      },
      'LOGIN-MODAL': {
        'HEAD': 'Please enter your dewabit access data to log in:',
        'E-MAIL': 'Email',
        'E-MAIL-PLACEHOLDER': 'Enter email address',
        'PASSWORD': 'Password',
        'PASSWORD-PLACEHOLDER': 'Enter password',
        'LOGIN': 'Login',
        'LOGIN-SUCCESS': 'Login successful',
      },
      'COMMUNITY-PUSH': {
        'NEW-POST': 'New post by {{user}}',
        'NEW-THREAD': 'New thread by {{user}}: {{threadName}}',
      },
      'LOGOUT-SUCCESS': 'You have successfully logged off',
      'CTA-REGISTER': {
        'CTA-HEAD': 'Create your free account now!',
        'CTA-BODY': 'Registration and testing risk-free without providing payment information possible',
        'CTA-BTN': 'Register now',
      }
    }
  },
};

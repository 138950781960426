import { Component, Inject, OnInit } from '@angular/core';
import { MetaService } from '@core/services/meta.service';
import { Gtag } from '@shared/gtag/gtag.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '@environment';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { PlatformService } from '@bws-dev/ngx-bws-shared';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`,
  styles: []
})
export class AppComponent implements OnInit {

  private previousUrl: string = '';

  constructor(private meta: MetaService,
              private gtag: Gtag,
              @Inject(DOCUMENT) private document: Document,
              private localize: LocalizeRouterService,
              private router: Router,
              private platformService: PlatformService) {
  }

  ngOnInit() {
    this.meta.init();
    this.document.documentElement.setAttribute('version', environment.version);

    // Scroll to top (help + feature page excluded)
    this.previousUrl = this.router.routerState.snapshot.url;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(filter(() => this.platformService.isBrowser))
      .subscribe(r => {
        const currentUrl = (r as NavigationEnd).urlAfterRedirects;
        const helpUrl = this.localize.translateRoute('/help').toString();
        const featuresUrl = this.localize.translateRoute('/features').toString();
        const includesUrl = (route: string): boolean => {
          return route.includes(helpUrl) || route.includes(featuresUrl)
        }
        if (includesUrl(this.previousUrl) && includesUrl(currentUrl)) {
          // Don't scroll up as we are on same page
        } else {
          window.scroll(0,0);
        }
        this.previousUrl = currentUrl;
      });
  }

}

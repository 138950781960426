export enum UserRoleType {
  ADMIN = 'admin',
  USER = 'user',
  SUPPORT = 'support',
}

export interface AuthUserModel {
  userId: number;
  email: string;
  firstName: string;
  lastName: string;
  nickname: string;
  language: string;
  role: UserRoleType;

  // Token
  access?: {
    expires: number;
    token?: string;
  };
  refresh: {
    expires: number;
    token: string;
  };

  notificationChannel: string;

}

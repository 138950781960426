import { ModuleWithProviders, NgModule } from '@angular/core';
import { GtagEventDirective } from '@shared/gtag/gtag-event.directive';
import { Gtag } from '@shared/gtag/gtag.service';
import { GtagConfig } from '@shared/gtag/interfaces';


@NgModule({
  declarations: [GtagEventDirective],
  exports: [GtagEventDirective],
  providers: [Gtag]
})
export class GtagModule {
  public static forRoot(config: GtagConfig): ModuleWithProviders<GtagModule> {
    return {
      ngModule: GtagModule,
      providers: [Gtag, { provide: 'config', useValue: config }]
    };
  }
}
